function HouseDetails({ details }) {
  const formatValue = (value) => {
    if (typeof value === 'string') {
      return value.replace(/, /g, ' • '); // Reemplaza comas con puntos.
    }
    return value;
  };

  return (
    <>
      <h3 className="text-2xl font-semibold mt-10">Additional Details</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-gray-600 mt-4">
        {details.map((detail, index) => (
          <div key={index} className="flex items-center space-x-2">
            <div className="flex-shrink-0">
              {detail.icon}
            </div>
            <div>
              <span className="font-normal">{detail.name}: </span>
              <span className="font-bold">{formatValue(detail.value)}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default HouseDetails;
