import React from 'react';

function UserInfo({ user }) {
  if (!user) return <p>Cargando...</p>;

  return (
    <div className="w-full lg:w-3/4 flex flex-col justify-start items-start">
      <div className="mt-4 flex flex-col lg:flex-row items-center justify-center">
        <img src={user.photoURL} alt={`${user.firstname} ${user.lastname}`} className="w-72 h-72 lg:w-32 lg:h-32 rounded-full mr-4 object-cover" />
        <div className="mt-4 lg:mt-0">
          <p className="text-gray-600 text-sm">{user.email}</p>
          <h2 className="text-xl font-bold">{user.firstname} {user.lastname}</h2>
          <p className="text-sm mt-2 text-yellow-600 font-bold">{user.cellphone}</p>
        </div>
      </div>
      {/* About us */}
      <div className="mt-4">
        <h2 className="text-xl font-bold w-full">About us</h2>
        <p className="text-gray-600 text-lg">{user.aboutUs}</p>

      </div>
      <a
        href={`mailto:${user.email}`}
        className="
      bg-yellow-600 
      font-bold 
      text-white
       px-4 py-2 
       rounded-full
       cursor-pointer

        mt-4">Contact</a>
    </div>
  );
}

export default UserInfo;
