import React, { useState, useRef } from 'react';

function CarruselComponent({ imageUrls }) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const carouselRef = useRef(null);  // 1. Agregar referencia

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleNext = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const handlePrev = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + imageUrls.length) % imageUrls.length);
  };

  const toggleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      carouselRef.current.requestFullscreen().catch(err => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    }
  };

  return (
    <div className="flex flex-col md:flex-row gap-5" ref={carouselRef}>  {/* Asignar la referencia aquí */}
         <div className="flex flex-row md:flex-col space-x-2 md:space-x-0 md:space-y-2 mt-5 md:mt-0 overflow-x-auto">
        {imageUrls.map((img, index) => (
          <img
            key={index}
            src={img}
            alt={`Thumbnail ${index}`}
            onClick={() => handleThumbnailClick(index)}
            className={`
           
            
            w-20 h-20 cursor-pointer ${index === selectedImageIndex ? 'border-2 border-blue-500' : ''}`}
           style={{objectFit: 'cover'}}
          />
        ))}
      </div>
      <div className="relative flex-1 flex items-center justify-center">
        <button onClick={handlePrev} className="absolute left-0 p-4 text-white bg-black bg-opacity-50 h-full">←</button>
        <img
          src={imageUrls[selectedImageIndex]}
          alt={`Main ${selectedImageIndex}`}
          onClick={toggleFullScreen}
          className="cursor-pointer"
          style={{ height: isFullScreen ? '100vh' : '500px', objectFit: 'cover', width: '100%'}}
        />
        <button onClick={handleNext} className="absolute right-0 p-4 text-white bg-black bg-opacity-50 h-full">→</button>
      </div>
     
    </div>
  );
}

export default CarruselComponent;
