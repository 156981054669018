function HouseTitle({ title, price, subTitle }) {
    return (
      <div className="flex flex-col  justify-start">
        {title && (<h1 className="text-2xl font-bold text-gray-900">{title}</h1>)}
        {price && (<h2 className="text-2xl font-bold text-green-700 mb-5">${price}</h2>)}
        {subTitle && (<h1 className="text-xl font-semibold text-gray-900">{subTitle}</h1>)}
      </div>
    );
  }
  
  export default HouseTitle;
  