import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "./Loading";
import { db } from "../firebase";
import { collection, doc, getDoc } from "firebase/firestore";
import NavbarLDG from "../components/NavbarLDG";
import CarruselComponent from "../components/CarruselComponent";
import HouseTitle from "../components/HouseTitle";
import HouseDetails from "../components/HouseDetails";
import ProfileComponent from "../components/Profile";

import Divider from "../components/Divider";

import {
  FaHome,
  FaBed,
  FaBath,
  FaLandmark,
  FaBuilding,
  FaUtensils,
  FaWrench,
  FaMoneyBillWave,
  FaLayerGroup,
} from "react-icons/fa";
import NoListingFound from "../components/NoListingFound";
import Footer from "../components/Footer";

function Listing() {
  const [listing, setListing] = useState({});
  const [creating, setCreating] = useState(false);

  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const listingsCollection = collection(db, "listings");
        const listingDocRef = doc(listingsCollection, id);
        const listingDoc = await getDoc(listingDocRef);

        if (listingDoc.exists()) {
          //const canShowThisListing
          setCreating(true);
          if (listingDoc.data().formData && listingDoc.data().formData.isHosted) {
            setListing(listingDoc.data());
            setCreating(false);
          } else {
            console.log("No such listing!");
            setListing(null); // or some error state that you can use to inform the user
          }
        } else {
          console.log("No such listing!");
          setListing(null); // or some error state that you can use to inform the user
          setCreating(false);
        }
        setLoading(false); // Move this here instead of using a timer
      } catch (error) {
        console.error("Error fetching listings:", error);
        setListing(null); // or some error state that you can use to inform the user
        setLoading(false); // Move this here instead of using a timer
        setCreating(false);
      }
    };

    fetchData();
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(timer);

  }, []);



  useEffect(() => {
    if (creating) {
      // fetch data every 5 seconds
      const interval = setInterval(() => {
        const fetchData = async () => {
          try {
            const listingsCollection = collection(db, "listings");
            const listingDocRef = doc(listingsCollection, id);
            const listingDoc = await getDoc(listingDocRef);

            if (listingDoc.exists()) {
              //const canShowThisListing
              setCreating(true);
              if (listingDoc.data().formData && listingDoc.data().formData.isHosted) {
                setListing(listingDoc.data());
                setCreating(false);
              } else {
                console.log("No such listing!");
                setListing(null); // or some error state that you can use to inform the user
              }
            } else {
              console.log("No such listing!");
              setListing(null); // or some error state that you can use to inform the user
              setCreating(false);
            }
            setLoading(false); // Move this here instead of using a timer
          } catch (error) {
            console.error("Error fetching listings:", error);
            setListing(null); // or some error state that you can use to inform the user
            setLoading(false); // Move this here instead of using a timer
            setCreating(false);
          }
        };

        fetchData();
      }, 5000);

    }
  }, [creating]);


  if (loading) {
    return <Loading message="Loading..." />;
  }

  if (creating) {
    return <Loading message="Creating..." />;
  }

  if (!listing) {
    // Cambiado la condición
    return <>
      <NavbarLDG />

      <NoListingFound></NoListingFound>
      <Footer />
    </>;
  }

  const iconMapping = {
    "State Of Conservation": <FaWrench color="green" />,
    "Selling Price": <FaMoneyBillWave color="green" />,
    "Kitchen": <FaUtensils color="green" />,
    "Views": <FaHome color="green" />,
    "Bathrooms": <FaBath color="green" />,
    "Type": <FaHome color="green" />,
    "Year Of Construction": <FaBuilding color="green" />,
    "Land Type": <FaLandmark color="green" />,
    "Rooms": <FaBed color="green" />,
    "Outdoor Features": <FaHome color="green" />, // Puedes cambiar este ícono según sea necesario
    "Constructed Levels": <FaLayerGroup color="green" />,
    // ... [otros mapeos de iconos según se requiera]
  };

  const camelCaseToTitle = (str) => {
    return str
      .replace(/([A-Z])/g, " $1") // inserta un espacio antes de las letras en mayúscula
      .replace(/^./, (match) => match.toUpperCase()); // convierte la primera letra a mayúscula
  };

  let detailsFromDB = [];
  if (listing && listing.additionalDetails) {
    detailsFromDB = Object.entries(listing.additionalDetails)
      .filter(([key, value]) => value !== null && value !== "")
      .map(([key, value]) => {
        const titleKey = camelCaseToTitle(key);
        return {
          icon: iconMapping[titleKey] || null,
          name: titleKey,
          value: value,
        };
      });
  }

  const formatCurrency = (number) => {
    return `$${number.toLocaleString()}`;
  };

  const generateEmbedURL = (address) => {
    const baseURL = "https://www.google.com/maps/embed/v1/place";
    const apiKey = "AIzaSyAG4yy1Svj0JYpjhGa2w0r1uDz5QVbsE_w"; // Asegúrate de mantener tu clave API segura y no exponerla directamente en el código del lado del cliente

    // Construye la URL
    const embedURL = `${baseURL}?key=${apiKey}&q=${encodeURIComponent(
      address
    )}`;

    return embedURL;
  };

  return (
    <div>
      <NavbarLDG />
      {/* SCROLL PRINCIPAL. CUANDO SE HAGA SCROLL, QUIERO QUE BAJE ESTE COMPONENTE PRIMERO. CUANDO YA TERMINE EL COMPONENTE
      QUIERO QUE BAJE EL SEGUNDO COMPONENTE. MIENTRAS, QUIERO QUE SE MUESTRE ESTATICO. */}
      <div className="flex flex-col lg:flex-row justify-between p-8 lg:space-y-0 max-w-xs:ml-0 max-w-xs:mr-0 lg:p-20">
        <div className="flex flex-col justify-center space-y-4 w-full lg:w-2/3 border border-gray-100 p-4">
          {listing.images && listing.images.length > 0 &&
            (<CarruselComponent
              imageUrls={listing.images || []} // Usando listing.images como ejemplo
              className="border border-gray-400 p-4"
            />)}

          {/* <ShareButtons className="border border-gray-100 p-4" /> */}
          <Divider className="border border-gray-100" />
          <HouseTitle
            title={listing.houseData?.title || "Title not available"}
            price={listing.houseData?.price || "Price not available"}
            subTitle={listing.houseData?.subtitle || "Subtitle not available"}
            className="border p-4"
          />
          <p className="text-gray-600 mt-4 text-justify w-fit">
            {listing?.response || "Response not available"}
          </p>

          <HouseDetails details={detailsFromDB} />
          <Divider />
          {listing.address && (
            <iframe
              src={generateEmbedURL(listing?.address)}
              height="400"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          )}
        </div>

        <div className="w-full lg:w-1/2 mt-4 lg:mt-0 border border-gray-100 p-4">
          {/* <div className="flex flex-col items-center justify-center space-y-2">
              <h2 className="text-xl font-semibold text-gray-900">ID: {id}</h2>
              <h3 className="text-lg font-normal text-gray-700">
                {listing.address || "Address not available"}
              </h3>
              <p className="text-xl font-bold text-gray-900">
                {formatCurrency(listing.houseData.price) ||
                  "Price not available"}
              </p>
            </div> */}

          <div className="flex flex-col items-center space-y-2  p-7 ">
            <h2 className="text-xl font-semibold text-gray-900">
              Do you need more information?
            </h2>
            <h3 className="text-lg font-normal text-gray-700">
              Contact the agent
            </h3>
          </div>
          {listing.userData && (
            <ProfileComponent
              id={listing.userData.id}
              imageUrl={listing.userData.photoURL}
              name={
                listing.userData.firstname + " " + listing.userData.lastname
              }
              phone={listing.userData.cellphone}
              email={listing.userEmail}
              className="border border-gray-100 "
            />
          )}

          <Divider />
        </div>
      </div>
    </div>
  );
}

export default Listing;
