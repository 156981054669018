import React from 'react';
import NavbarLDG from '../components/NavbarLDG';  // Importa el componente Navbar
import Hero from '../components/Hero';
import Footer from '../components/Footer';

function Home() {
  return (
    <div className="">
      <NavbarLDG />  {/* Utiliza el componente Navbar aquí */}
      <Hero/>
      <Footer/>
      {/* Puedes agregar más contenido o componentes aquí según lo necesites */}
    </div>
  );
}

export default Home;
