import React from 'react';

function SearchBar() {
  return (
    <div className="flex items-center mb-10">
      <div className="flex w-full border border-yellow-200 rounded">
        <input
          type="text"
          className="block w-full px-4 py-2 text-yellow-700 bg-white border rounded-md focus:border-yellow-400 focus:ring-yellow-300 focus:outline-none focus:ring focus:ring-opacity-40"
          placeholder="Search..."
        />
        <button className="px-4 text-white bg-yellow-400 border-l rounded">
          Search 
        </button>
      </div>
    </div>
  );
}

export default SearchBar;