import React, { useState, useEffect } from 'react';
import NavbarLDG from '../components/NavbarLDG';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { collection, doc, getDoc, query, getDocs, where } from 'firebase/firestore';

import UserInfo from '../components/UserInfo'; // Importa el componente ProfileInfo
import UserListings from '../components/UserListings'; // Importa el componente UserListings
import ContactForm from '../components/ContactForm'; // Importa el componente ContactForm

function Profile() {
  const [user, setUser] = useState({
    aboutUs: "",
    cellphone: "",
    email: "",
    firstname: "",
    lastname: "",
    photoURL: "",
  });

  const [listings, setListings] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const getUserData = async () => {
        try {
          const usersCollection = collection(db, "users");
          const userDocRef = doc(usersCollection, id);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists) {
            setUser(userDoc.data());
            getListingsData(id);
          } else {
            console.error("No se encontró un usuario con ese UID");
          }
        } catch (error) {
          console.error("Error al obtener el usuario:", error);
        }
      }
      getUserData();
    }
  }, [id]);

  const getListingsData = async (userID) => {
    try {
      const listingsCollection = collection(db, "listings");
      const userListingQuery = query(listingsCollection, where("userID", "==", userID));
      const querySnapshot = await getDocs(userListingQuery);

      const userListingData = [];
      querySnapshot.forEach((doc) => { 
        userListingData.push(doc.data());
      });
      setListings(userListingData);
    } catch (error) {
      console.error("Error al obtener los listings:", error);
    }
  }

  if (!user) return <p>Cargando...</p>;

  return (
    <>
      <NavbarLDG />
      <div className="p-10 lg:p-10 xl:p-20">
        <div className="lg:flex">
          <div className="lg:w-1/2 xl:w-2/3 lg:flex-grow mb-2 mr-2">
            <UserInfo user={user} />
            <UserListings listings={listings} />
          </div>
          {/* <div className="lg:w-1/2 xl:w-1/3">
            <ContactForm />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Profile;
