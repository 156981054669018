// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA2HF64AEELeIKN4J6Y8AZGLXJM6GUn7lM",
  authDomain: "listing-description-generator.firebaseapp.com",
  projectId: "listing-description-generator",
  storageBucket: "listing-description-generator.appspot.com",
  messagingSenderId: "36141368001",
  appId: "1:36141368001:web:3bc387c7fe35aea6840a40",
  measurementId: "G-H69DXJYJSB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app);

export { db };