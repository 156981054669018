import React from "react";
import {
  Navbar,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";

function NavbarLDG() {
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false),
    );
  }, []);



  return (
    <Navbar className="mx-auto  py-2 px-4 lg:px-8 lg:py-4 shadow-md">
      <div className="container mx-auto flex items-center justify-between ">
        <div className="flex items-center">
          <img src={process.env.PUBLIC_URL + "/LDGLogo.png"} alt="LDG Logo" className="h-10 w-auto mr-4" />
          <Typography
            as="a"
            href="#"
            className="cursor-pointer 
            py-1.5 
            text-lg 
            font-bold 
            leading-relaxed 
            inline-block 
            text-slate-950
            whitespace-nowrap "
          >
          </Typography>
        </div>

        <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </IconButton>
      </div>

    </Navbar>
  );

}

export default NavbarLDG;