import React from 'react';
import Lottie from "lottie-react";
import loadingAnimation from '../assets/loading.json';
import NavbarLDG from '../components/NavbarLDG';

function Loading({ message }) {
  return (
    <>
      <NavbarLDG />
      <div className="flex justify-center items-center flex-col">
        <Lottie animationData={loadingAnimation} />
        <h1 className="text-4xl font-bold text-gray-900">{message}</h1>
      </div>
    </>
  );
}


export default Loading;
