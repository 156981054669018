// Profile.js en ../components/

import React from "react";

function ProfileComponent({ imageUrl, id, name, phone, alternatePhone, email }) {
  return (
    <div className="flex flex-col items-center space-y-2">
      <a
        href={`../profile/${id}`}

      >

        <img
          src={imageUrl}
          alt="Profile"
          className="w-32 h-32 rounded-full border-2 border-gray-300"
        />
      </a>
      <a href={`../profile/${id}`} className="text-xl font-semibold text-gray-900">{name}</a>
      <a
        href={`tel:+1${phone}`}
        className="text-yellow-600 font-semibold hover:underline transition duration-200 ease-in-out hover:text-yellow-800 hover:font-bold "
      >{phone}</a>
      <a
        href={`mailto:${email}`}
        className="text-yellow-600 font-semibold hover:underline transition duration-200 ease-in-out hover:text-yellow-800 hover:font-bold "
      >{email}</a>
      {/* Button to view portfolio */}
      <a
        href={`../profile/${id}`}
      >
        <button className="bg-yellow-600 hover:bg-yellow-800 text-white font-bold py-2 px-4 rounded-full">
          View Portfolio
        </button>
      </a>
    </div>
  );
}

export default ProfileComponent;
