import React from "react";
import heroImg from "../assets/3d-view-house.png";
import SearchBar from "./SearchBar";
import LazyLoad from "react-lazyload";
import { CSSTransition } from "react-transition-group";
import "./Hero.css"; // Importa el archivo CSS para las animaciones

function Hero() {
  return (
    <div className="bg-gray-100 p-8 text-center md:text-left h-3/4 items-center justify-center flex ">
      <CSSTransition in={true} appear={true} timeout={1000} classNames="slide">
        <div className="flex flex-col md:flex-row items-center justify-start text-start space-y-4 md:space-y-0 md:space-x-4">
          <div className="flex-1">
            <h2 className="text-4xl font-bold mb-4 text-gray-900">
              LDG Hosting
            </h2>
            <p className="mb-4 text-gray-800 text-4xl font-normal">
              We Help You Share Your{" "}
              <span className="text-yellow-400 font-bold">Portfolio</span> With
              The World
            </p>
            <SearchBar />
            <button className="bg-yellow-400 text-gray-800 font-bold px-4 py-2 rounded">
              Learn More
            </button>
          </div>
          <div
            className="flex-1 flex items-center justify-center"
            style={{ height: "600px", width: "600px" }}
          >
            <LazyLoad
              height={600}
              offset={100}
              placeholder={
                <div style={{ height: "600px", width: "600px" }}></div>
              }
            >
              <img
                src={heroImg}
                alt="Representative image of LDG Hosting"
                className="w-auto h-fit mx-auto"
              />
            </LazyLoad>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default Hero;
