import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Importar los componentes desde la carpeta src/views
import Home from './views/Home';
import Listing from './views/Listing';
import Profile from './views/Profile';

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/listing/:id" element={<Listing />} />
          <Route path="/profile/:id" element={<Profile />} /> {/* Ajustado aquí */}
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
