import React from "react";
import LDGLogo from "../assets/LDGLogo.png";

function Footer() {
  return (
    <footer className="bg-white text-gray-900 py-6">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex flex-wrap justify-between items-center">
          <div className="w-full md:w-1/4 mb-4 md:mb-0">
            <div className="flex items-center">
              <img src={LDGLogo} alt="LDG Logo" className="h-10 w-auto mr-4" />
              <h3 className="text-xl font-bold mb-2">Hosting</h3>
            </div>
            <p
              className="text-gray-950 leading-normal text-base mb-4 md:mb-8 w-3/4
            "
            >
              Bringing your portfolio to the world.
            </p>
          </div>

          <div className="w-full md:w-1/4 mb-4 md:mb-0">
            <h3 className="text-xl font-bold mb-2">Contact</h3>
            <p>Email: info@ldghosting.com</p>
            <p>Phone: +1 (123) 456-7890</p>
          </div>
          <div className="w-full md:w-1/4">
            <h3 className="text-xl font-bold mb-2">Follow Us</h3>
            <p>Stay connected on our social media channels.</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
