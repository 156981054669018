import React from "react";

function UserListings({ listings }) {
  return (
    <div className="mt-10 w-full overflow-x-auto">
      <h2 className="text-xl font-bold mb-4">User Listings</h2>
      <table className="min-w-full border border-collapse">
        <thead>
          <tr className="bg-gray-100">
            <th className="border p-4">Listing Title</th>
            <th className="border p-4">Price</th>
            {/* Agrega más columnas según los campos de tu objeto listing */}
          </tr>
        </thead>
        <tbody>
          {listings.map((listing, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
            >
              <td className="border p-4">
                <div className="flex items-center justify-start">

                  {!listing.images && (<img
                    src="https://via.placeholder.com/150"
                    alt={listing.houseData?.title || "Listing image"}
                    className="w-36 h-36 object-cover" />)}

                  {listing.images.length > 0 && (<img
                    src={listing.images[0]}
                    alt={listing.houseData?.title || "Listing image"}
                    className="w-36 h-36 object-cover"
                  />)}

                  <a
                    className="ml-4 w-64 text-blue-500 hover:underline"
                    href={`/listing/${listing.formID}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {listing.houseData?.title || "Title not available yet"}
                  </a>
                </div>
              </td>
              <td className="border p-4">${listing.price}</td>
              {/* Agrega más celdas según los campos de tu objeto listing */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UserListings;
